import './index.html';
import './style.scss';
import {imageLazyLoading} from '../../scripts/functions/imageLazyLoading';
import {animations} from '../../scripts/general/animations';
import {gsap} from "gsap";

/**
 *
 * @param block {HTMLElement}
 * @returns {Promise<void>}
 */
export default async (header) => {
  // region save headerSticky height
  const burgerMenu = header.querySelector('.burger-menu-wrapper');
  const menuLinks = header.querySelector('.navbar');
  if (!burgerMenu) return;
  const burgerTl = gsap.timeline({paused: true});
  const burgerSpans = burgerMenu.querySelectorAll('span');
  gsap.set(burgerSpans, {transformOrigin: 'center'});
  burgerTl
    .to(burgerSpans, {
      y: gsap.utils.wrap([`0.8rem`, 0, `-0.8rem`]),
      duration: 0.25,
    })
    .set(burgerSpans, {autoAlpha: gsap.utils.wrap([1, 0, 1])})
    .to(burgerSpans, {rotation: gsap.utils.wrap([45, 0, -45])})
    .set(burgerSpans, {rotation: gsap.utils.wrap([45, 0, 135])})

  burgerMenu.addEventListener('click', function () {
    if (burgerMenu.classList.contains('burger-menu-active')) {
      burgerMenu.classList.remove('burger-menu-active');
      menuLinks.classList.remove('header-links-active');
      header.classList.remove('header-active');
      const activeItem = header.querySelector('.menu-item-has-children.menu-item-active');
      activeItem && activeItem.classList.remove('menu-item-active');
      burgerTl.reverse();
    } else {
      burgerMenu.classList.add('burger-menu-active');
      menuLinks.classList.add('header-links-active');
      header.classList.add('header-active');
      burgerTl.play();
      gsap.fromTo(menuLinks.querySelectorAll('.sub-menu'), {
        y: 30,
        autoAlpha: 0,
      }, {
        y: 0,
        autoAlpha: 1,
        stagger: .05,
        duration: .4,
        delay: .5,
      });
    }
  });

  // endregion save headerSticky height
  const menuItems = header.querySelectorAll('.menu-item-has-children');
  const navMenu = header.querySelector('.btn-navmenu');
  const overLay = header.querySelector('.overlay');
  const menuLink = header.querySelector('.menu-item')
  const openMegaMenuICon = header.querySelectorAll('.open-mega-menu');
  const btnBack = header?.querySelectorAll('.back-link');
  const mobileMedia = window.matchMedia('(max-width: 992px)');

  navMenu?.addEventListener('click', function () {
    if (navMenu.classList.contains('nav-menu-active')) {
      navMenu.classList.remove('nav-menu-active');
      menuLinks.classList.remove('header-links-active');
      menuLink.classList.remove('menu-item-active');
      header.classList.remove('header-active');
    } else {
      navMenu.classList.add('nav-menu-active');
      menuLinks.classList.add('header-links-active');
      header.classList.add('header-active');
      gsap.fromTo(menuLinks.querySelectorAll('.menu-item'), {
        y: 30,
        autoAlpha: 0,
      }, {
        y: 0,
        autoAlpha: 1,
        stagger: .05,
        duration: .2,
        delay: .2,
        clearProps: true
      });
    }
  });
  menuItems.forEach((menuItem) => {
    const menuItemBody = menuItem.querySelector('.sub-menu');
    menuItem?.addEventListener('click', (e) => {
      if (!mobileMedia.matches || !menuItemBody ) return;
      const isOpened = menuItem?.classList.toggle('menu-item-active');
      if (!isOpened) {
        gsap.to(menuItemBody, {height: 0});
      } else {
        gsap.to(Array.from(menuItems).map(otherMenuItem => {
          const otherMenuItemBody = otherMenuItem.querySelector('.sub-menu');
          if (otherMenuItemBody && menuItem !== otherMenuItem) {
            otherMenuItem?.classList.remove('menu-item-active');
            gsap.set(otherMenuItem, {zIndex: 1});
          }
          return otherMenuItemBody;
        }), {height: 0});
        gsap.set(menuItem, {zIndex: 2});
        gsap.to(menuItemBody, {height: 'auto'});
      }
    });
  });




  const color = "fixed";
  let lastScroll = 0;
window.addEventListener('scroll',()=>{
  const currentScroll = window.scrollY;
  currentScroll <=50 ? header.classList.remove(color) : header.classList.add(color);
})

  animations(header);
  imageLazyLoading(header);
};
